import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss'
})
export class PartnersComponent {
  @Input() pageloading:boolean;
  ImageArray=new Array(11).fill(0);
 
}
