import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppHeaderComponent } from './template/app-header/app-header.component';
import { AppFooterComponent } from './template/app-footer/app-footer.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PartnersComponent } from './template/partners/partners.component';
import { DropdownService } from './services/dropdown.service';
import {  provideHttpClient, withFetch } from '@angular/common/http';
import { ContentComponent } from './content/content.component';
import { HomeModule } from './home/home.module';
import { ArticlesModule } from './articles/articles.module';
import { GeneralModule } from './general/general.module';
import { SearchContextService } from './services/search-context-service';




@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    PartnersComponent,  
    ContentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,   
    ReactiveFormsModule,
    HomeModule,
    GeneralModule,
    ArticlesModule,
  ],
  providers: [
    provideClientHydration(),
    DropdownService ,
    SearchContextService,
    provideHttpClient(withFetch()) // Configure HttpClient to use fetch
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
