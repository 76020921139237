import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleViewPageComponent } from './articles/article-view-page/article-view-page.component';
import { HomeComponent } from './home/home.component';
import { ArticleListingComponent } from './articles/article-listing/article-listing.component';
import { PageNotFoundComponent } from './general/page-not-found/page-not-found.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  // { path: 'article-view-page', component: ArticleViewPageComponent },
  // {path:'young-bjs',component:ArticleListingComponent}
   { path: '', redirectTo: '', pathMatch: 'full' }, // Redirect root to /home
  // { path: '', component: HomeComponent },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'bjs-academy', 
    loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
  },
  {
    path: 'page',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule)
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: 'page-not-found' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


