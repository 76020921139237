<section id="footer" class="footer bgcolor-footer ">
  <!-- <div class="footer-top-div"></div> -->
  <div class="container-fluid ">
    <div id="partner" aria-label="Partner section footer" class="partner-section-box footer-absolute" *ngIf="!partnersLoading;else partnersNotLoaded" >
      <app-partners [pageloading]="partnersLoading" ></app-partners>
    </div>
    <ng-template #partnersNotLoaded>
      <div id="partner" aria-label="Partner section footer" class="partner-section-box footer-absolute"  >
        <app-partners [pageloading]="partnersLoading" ></app-partners>
      </div>
    </ng-template>
    <div class="footer-section">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 pb-5">
          <img src="bjs_logo_white.png" alt="">
          <ul class="pb-5 ps-0 d-sm-none d-md-block d-lg-block d-xl-none d-xxl-none" >
            <li class="d-flex pt-5 text-white"  >
              <h4 class="footer-menu">Connect</h4>
              <hr class="small-hr">
            </li>
            <li>
              <div class="social-media pt-4">
                <a *ngFor="let media of socialMediaLink" class="navbar-brand navbar-brand-secondary-icons"
                  target="_blank" href="{{media.value}}" role="link" [attr.aria-label]="'vist link'+ media.value"
                  title="media.label">
                  <fa-icon aria-hidden="true" [icon]="media.icon"></fa-icon>
                </a>
              </div>
            </li>
          </ul>

        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-Menu">Contact</h4>
            <hr>
          </div>

          <ul class="address-footer ps-0">
            <li>Salisbury House</li>
            <li>London Wall</li>
            <li>London</li>
            <li>United Kingdom</li>
            <li>EC2M 5PS</li>
          </ul>
          <ul class="ps-0">
            <li><u> <a class="">info&#64;bjsacademy.com</a></u></li>
          </ul>
          <ul class="pb-5 ps-0 d-md-none d-sm-block d-lg-none d-xl-block d-xxl-block">
            <li class="d-flex pt-5">
              <h4 class="footer-menu">Connect</h4>
              <hr>
            </li>
            <li>
              <div class="social-media">
                <a *ngFor="let media of socialMediaLink" class="navbar-brand navbar-brand-secondary-icons"
                  target="_blank" href="{{media.value}}" role="link" [attr.aria-label]="'vist link'+ media.value"
                  title="media.label">
                  <fa-icon aria-hidden="true" [icon]="media.icon"></fa-icon>
                </a>
              </div>
            </li>
          </ul>

        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-Menu">About</h4>
            <hr>
          </div>
          <ul class="ps-0 about-section">
            <li><a class="lonkContent" routerLink="/bjs-academy/">About BJS Foundation </a></li>
            <ul>Leadership
              <ul>
                <li><a class="lonkContent" routerLink="/page/about/council-of-management">Council Of Management</a>
                </li>
                <li><a class="lonkContent" routerLink="/page/about/executive-committee">Executive Committee</a></li>
                <li><a class="lonkContent" routerLink="/page/about/sub-committees">Sub-Committee</a></li>
                <li><a class="lonkContent" routerLink="/page/about/editorial-teams">Editorial Teams</a></li>
                <li> <a class="lonkContent" routerLink="/page/about/editorial-board">Editorial Board</a></li>
                <li><a class="lonkContent" routerLink="/page/about/executive-committee">Executive Committee</a></li>
                <li><a class="lonkContent" routerLink="/page/about/bjs-academy-team">BJS Academy team</a></li>
              </ul>
            </ul>
            <ul> BJS Foundation Partners
              <ul>
                <li><a class="lonkContent" routerLink="/page/about/strategic-partners-trainee">Strategic
                    Partners/Trainee</a></li>
                <li><a class="lonkContent" routerLink="/page/about/strategic-partners">Strategic Partners</a></li>
                <li><a class="lonkContent" routerLink="/page/about/development-partners">Development Partners</a></li>
                <li><a class="lonkContent" routerLink="/page/about/partner-societies">Partner Societies</a></li>
                <li><a class="lonkContent" routerLink="/page/about/educational-partners">Education Partnersrd</a></li>
                <li><a class="lonkContent" routerLink="/page/about/industry-Partners">Industry Partners</a></li>
              </ul>
            </ul>

            <ul>Charitable activities
              <ul>
                <li> <a class="lonkContent" routerLink="/page/about/editorial-apprenticeships">Editorial
                    apprenticeships</a></li>
              </ul>
            </ul>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3 contact-footer custom-footer-padding">
          <div class="d-flex">
            <h4 class="footer-Menu">Company</h4>
            <hr>
          </div>
          <ul class="ps-0 company-section">
            <li><a class="lonkContent" routerLink="/academy/">Academy</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-journals">BJS Journals</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-institute"> BJS Institute</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-award">BJS Award</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-partners/strategic-partners">Strategic partners</a></li>
            <li><a class="lonkContent"  (click)="openJoinModal()" >Join BJS Academy</a></li>
            <li><a class="lonkContent" routerLink="/page/contact"> Contact</a></li>
            <li><a class="lonkContent" routerLink="/page/terms-of-use">Terms of use</a></li>
            <li><a class="lonkContent" routerLink="/page/privacy-policy">Privacy policy</a></li>
            <li><a class="lonkContent" routerLink="/page/copyright-statement">Copyright statement</a></li>
            <li><a class="lonkContent" routerLink="/page/bjs-foundation-policy-on-diversity">Diversity, Equity and
                Inclusion policy</a></li>
            <li><a class="lonkContent" routerLink="/page/accessibility">Accessibility</a></li>
            <li><a class="lonkContent" routerLink="/page/cookies">Cookies</a></li>
          </ul>
        </div>

      </div>
    </div>


  </div>
  <div class="footer-copyright">
    <hr>
    <p>Copyright © 2024, BJS Foundation is a Company Limited by Guarantee (01248899)</p>
  </div>
</section>
<app-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-modal>
